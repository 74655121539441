import 'bootstrap';
export function show(config){
	var defaultSettings = {
		message: '',
		color: 'primary',
		time: 4000,
		startTime: Date.now()
	};
	const toastId = generateToastId();
	var settings = {...defaultSettings, ...config}
	var template = `<div id="${toastId}" class="toast text-bg-${settings.color} fade show ${settings.type}" role="alert" aria-live="assertive" aria-atomic="true">
		<div class="d-flex">
			<div class="toast-body">
				${settings.message}
			</div>
			<button type="button" class="btn-close btn-close-white me-2 m-auto close-toast" data-bs-dismiss="toast" aria-label="Zapri" title="Zapri" data-toggle="tooltip"></button>
		</div>
		<div class="progressTime" style="width:100%;"></div>
	</div>`;
	$('.toast-container').append(template);
	
	$(document).on('click',`#${toastId} .close-toast`,function(e){
		e.preventDefault();
		clearInterval(countdownVisual);
		clearInterval(removeToast);
		$(`#${toastId}.toast`).remove();
	});
	
	const countdownVisual = setInterval(function(){
		const currentTime = new Date();
		let timeElapsed = currentTime - settings.startTime;
		const percent = (100 * timeElapsed) / settings.time;
		const percentWidth = 100 - parseInt(percent);
		$(`#${toastId}.toast .progressTime`).css("width", `${percentWidth}%`);
	}, 50);
	
	const removeToast = setTimeout(function(){
		clearInterval(countdownVisual);
		$(`#${toastId}.toast`).remove();
	},settings.time);
}

function generateToastId(length = 8) {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let toastId = 'toast-';
	for (let i = 0; i < length; i++) {
		toastId += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	return toastId;
}

export function hideByType(config){
	$(`.toast.${config.type}`).remove();
}