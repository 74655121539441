
//import bootbox from "bootbox";
//const bootbox = require('bootbox');

var backbtn = document.getElementById("backbtn");
backbtn && backbtn.addEventListener("click", function(e){
	if (history.length){
		e.preventDefault();
		e.stopPropagation();
		
		history.back();
		return false;
	}
});

function saveNumberOfTabs(){
	
	sessionStorage.setItem('tabId', fnc.generateId(8, 'tab'));
	
	window.addEventListener('load', () => {
		
		let getTabLinks = JSON.parse(localStorage.getItem('tabLinks'));
		if(getTabLinks == null || !getTabLinks){
			getTabLinks = {};
		}
		
		const tabId = sessionStorage.getItem('tabId');
		getTabLinks[tabId] = window.location.pathname;
		
		localStorage.setItem('tabLinks', JSON.stringify(getTabLinks));
		localStorage.setItem('tabCount', Object.keys(getTabLinks).length);
		
	});
	
	window.addEventListener('beforeunload', () => {
		
		const getTabLinks = JSON.parse(localStorage.getItem('tabLinks'));
		
		const tabId = sessionStorage.getItem('tabId');
		delete getTabLinks[tabId];
		
		localStorage.setItem('tabLinks', JSON.stringify(getTabLinks));
		localStorage.setItem('tabCount', Object.keys(getTabLinks).length);
	});
	
}

function tooltip(){
	$('[data-toggle="tooltip"]').tooltip({ 'html': true });
	
	/* $("body").on('DOMNodeInserted', function(e) {
		$(e.target).find('[data-toggle="tooltip"]').tooltip();
	}); */
}

function scrollBack(){
	if($('body.admin').length === 0){
		if(document.getElementById("vloga")){
			const selector = '.scroll.mainList';
			if (localStorage.getItem("scrollPosition") != null) {
				$(selector).scrollTop(localStorage.getItem("scrollPosition"));
			}
			
			$(selector).on("scroll", function() {
				localStorage.setItem("scrollPosition", $(selector).scrollTop());
				
			});
		}else{
			localStorage.removeItem("scrollPosition");
		}
	}
}


function autosize(textarea){
	textarea = textarea[0];
	if(!$(textarea).hasClass("dont-resize")){
		autosizeaction(textarea); // on load
		$(textarea).on('keyup focus paste change', function(e){
			autosizeaction(textarea); // on change
		});
	}
}

function autosizeaction(textarea){
	if(textarea.scrollHeight > textarea.offsetHeight){ // samo povečaj, ne zmanjšuj
		setTimeout(function(){
			$(textarea).css({'height':(textarea.scrollHeight) + 'px', 'overflow': 'hidden', 'min-height': 'unset', 'resize':'none' });
		},10);
	}
}

function textareaExpand() {
	if($('#vloga, #porocilo').length){
		$('textarea').each(function(){ // onload current textarea
			autosize($(this));
		});
		
		/* $(document).on('DOMNodeInserted', function(e) {
			const areas = $(e.target).find('textarea');
			if(areas.length > 0){
				areas.each(function(){
					autosize($(this)); // onload new textarea
				});
			}
		});*/
		
		const observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				mutation.addedNodes.forEach(node => {
					if (node.nodeType === Node.ELEMENT_NODE) {
						const textareas = node.querySelectorAll('textarea');
						textareas.forEach(area => {
							autosize(area); // Apply autosize to new textareas
						});
					}
				});
			});
		});
		observer.observe(document.body, { childList: true, subtree: true });
		
	}
}


function catchEnter(){
	if($('#vloga, #porocilo').length){
		$(document).ready(function() {
			$('input').keydown(function(event){
				if(event.keyCode == 13) {
					event.preventDefault();
					return false;
				}
			});
		});
	}
}

var getWordCount = function(v){
	var matches = v.match(/\S+/g) ;
	return matches?matches.length:0;
}
function triggerMaxWords(){
	$(document).on('change', 'input[class*="maxwords"], textarea[class*="maxwords"]', function(){
		var maxWords = 1;
		var classes = $(this).attr('class').split(/\s+/);
		$.each(classes, function(i,name) {
			if (name.indexOf('maxwords') === 0) {
				maxWords = parseInt(name.replace('maxwords',''));
			}
		});
		if(getWordCount($(this).val()) > maxWords){
			var words = $(this).val().split(' ');
			$(this).val(words.slice(0,maxWords).join(' '));
		}
	});
}

function startLoadingOnClick(){
	$('a.loadingTrigger').on('click', function(e){
		$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
	});
}

function changeLoadingPosition(){
	$('body').on('classChangeLoading', function(e, inside = false) {
		let offsetTop = 0;
		
		const clientHeight = document.documentElement.clientHeight;
		const fromTop	= window.pageYOffset || document.documentElement.scrollTop;
		
		const heightLoadingHalf = 150 / 2;
		const opacityLoadingDiv = document.querySelector(".opacity-loading").getBoundingClientRect();
		const divX = opacityLoadingDiv.x;
		const divTop = opacityLoadingDiv.top;
		const divHeight = opacityLoadingDiv.height;
		
		if( divTop >= 0 && divTop+divHeight <= clientHeight ){ // cel div na ekranu
			offsetTop = divHeight/2 - heightLoadingHalf;
			if(inside){
				offsetTop = offsetTop + divTop;
			}
		}
		else if( divX + divHeight < fromTop + clientHeight ){ // spodnji rob na ekranu
			const onScreenHeight = divHeight - divTop;
			offsetTop = onScreenHeight / 2 - heightLoadingHalf;
		}
		else if( divTop >= 0 ){ // zgornji rob na ekranu
			offsetTop = divTop / 2 + heightLoadingHalf;
		}
		else{ // vmes div
			offsetTop = clientHeight/2 - heightLoadingHalf;
			if(divTop > 0){
				offsetTop += divTop;
			}
		}
		
		$("body").get(0).style.setProperty("--loadingTop", offsetTop+"px");
		
		$('.side .btns').addClass('opacity-loading-btns');
	});
	
	$('body').on('stopLoading', function() {
		$('.opacity-loading').removeClass('opacity-loading');
		$('.opacity-loading-btns').removeClass('opacity-loading-btns');
	});
}

function triggerAnchorClick(){
	if($('#vloga, #porocilo').length){
		$(document).on('click', '.note', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var elementID = $(this).data('load');
			var html = $('#'+elementID).html();
			if(typeof html !== 'undefined'){
				bootbox.alert(html);
			}
		})
	}
}

function successTooltip(){
	const url = new URL(window.location.href);
	const urlParams = new URLSearchParams(window.location.search);
	if (urlParams.get('success') === 'true') {
		
		const type = urlParams.get('type');
		
		let text = '';
		if(type == 'nocert'){
			text = `Vloga je bila uspešno oddana.`;
		}
		if(type == 'cert'){
			text = `Vaša vloga je bila uspešno digitalno podpisana in oddana.`;
		}
		if(type == 'supplement'){
			text = `Dopolnitev vloge je bila uspešno oddana.`;
		}
		if(type == 'report'){
			text = `Poročilo je bilo uspešno oddano.`;
		}
		if(type == 'report_supplement'){
			text = `Dopolnitev poročila je bila uspešno oddana.`;
		}
		
		if(text != ''){
			toasts.show({
				message:text,
				color: 'success',
				time: 100000
			});
		}
		
		url.searchParams.delete('success');
		url.searchParams.delete('type');
		window.history.pushState({}, '', url);
	}
}

function saveWithCtrlS(){
	document.addEventListener('keydown', e => { // CTRL + S
		if (e.ctrlKey && e.key === 's') {
			e.preventDefault();
			e.stopPropagation();
			document.querySelector('.saveCtrlS').click();
		}
	});
}

$(function($){
	saveNumberOfTabs();
	tooltip();
	scrollBack();
	textareaExpand();
	catchEnter();
	triggerMaxWords();
	changeLoadingPosition();
	triggerAnchorClick();
	successTooltip();
	saveWithCtrlS();
	startLoadingOnClick();
	
});