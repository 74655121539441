import 'tinymce';

import 'tinymce/models/dom/';

import '../../lib/tinymce/sl_SI.js';
import '../../lib/tinymce/code_plugin.js';

import 'tinymce/themes/silver/theme';
//import 'tinymce/skins/content/default/content';
import 'tinymce/icons/default/icons';
//import 'tinymce/skins/ui/oxide/content';
import 'tinymce/skins/ui/oxide/skin';

import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/nonbreaking';

//import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';

import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/visualblocks';

tinymce.init({
	content_css: [ "/assets/css/tinymce/content.min.css", "/assets/css/tinymce/tinymce_custom.css" ],
	selector: 'textarea.tinymce',
	language: 'sl_SI',
	min_height: 600,
	max_height: 1000,
	
	branding: false,
	promotion: false,
	
	plugins: "lists advlist table autoresize fullscreen nonbreaking searchreplace wordcount visualchars visualblocks supercode",
	
	menubar: 'edit format table view',
	menu: {
		edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
		format: { title: 'Format', items: 'bold italic underline superscript subscript | blocks align | nonbreaking | removeformat' },
		table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
		view: { title: 'View', items: 'visualchars visualblocks | fullscreen | wordcount' },
	},
	
	toolbar: "undo redo | blocks | bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | table | visualchars visualblocks | fullscreen | supercode",
	
	table_toolbar: 'tableprops tablerowprops tablecellprops tablecellvalign | tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
	
	visualchars_default_state: true,
	visualblocks_default_state: true,
	
	advlist_bullet_styles: 'default',
	
	supercode: {
		theme: 'chrome',
		fontSize: 14,
		wrap: true,
		autocomplete: true,
		iconName: 'sourcecode',
		//parser: myParserFunction,
		//renderer: myRendererFunction,
		language: 'html',
		shortcut: true,
		//aceCss: 'your custom CSS rules or fonts for ace',
		fontFamily: 'Monospace',
	}
	
});