
var downloadTimer;

function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
function expireCookie(cookieName){
	if( getCookie( cookieName ) ) {
		document.cookie = cookieName + "=" +";expires=Thu, 01 Jan 1970 00:00:01 GMT";
	}
}

function startDownloadCheckerIzjava(button, timeout) {
	var cookieName = "downloadDone";
	var downloadTimerAttempts = timeout;
	setCookie(cookieName, 0, downloadTimerAttempts);
	downloadTimer = window.setInterval(function () {
		var cookie = getCookie(cookieName);
		if ((typeof cookie === 'undefined' || cookie == '') || (downloadTimerAttempts == 0)) {
			button.attr('href', button.data('href'));
			button.html('Prenesi izjavo <img class="ms-2" src="/assets/images/download-w.svg">');
			window.clearInterval(downloadTimer);
			expireCookie(cookieName);
		}
		downloadTimerAttempts--;
	}, 1000);
}

function izjavaPDF(){
	$(".izjavapdf").on('click', function (e) {
		e.preventDefault();
		e.stopPropagation();
		
		window.location.href=$(this).attr('href');
		var link = $(this).attr('href');
		$(this).data('href', $(this).attr('href')).attr('href', '#');
		$(this).html('The document is being prepared');
		startDownloadCheckerIzjava($(this), 120);
	});
}


function startDownloadChecker(button, timeout) {
	var cookieName = "downloadDone";
	var downloadTimerAttempts = timeout;
	setCookie(cookieName, 0, downloadTimerAttempts);
	downloadTimer = window.setInterval(function () {
		var cookie = getCookie(cookieName);
		if ((typeof cookie === 'undefined' || cookie == '') || (downloadTimerAttempts == 0)) {
			button.attr('href', button.data('href'));
			button.html('Izvozi vlogo v PDF');
			window.clearInterval(downloadTimer);
			expireCookie(cookieName);
		}
		downloadTimerAttempts--;
	}, 1000);
}

function downloadPDF(){
	$(".downloadpdf, .downloadzip").on('click', downloadHandler);
	
	function downloadHandler(e) {
		e.preventDefault();
		e.stopPropagation();
		
		var link = $(this).attr('href');
		window.location.href = link;
		$(this).data('href', link).attr('href', '#');
		
		var fileType = $(this).hasClass('downloadpdf') ? 'PDF' : 'ZIP';
		$(this).html(fileType + ' se pripravlja');
		startDownloadChecker($(this), 120);
	}
}

$(function($){
	izjavaPDF();
	downloadPDF();
	
});