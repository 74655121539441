import mammoth from 'mammoth';

require(`./tinymce.js`);

if (typeof mammoth === 'undefined') {
	console.error('Mammoth.js is not loaded.');
}

function fileDropZoneDocument(){
	
	$(document).on('click', '.fileDropZoneDocument', function(e){
		e.preventDefault();
		e.stopPropagation();
		const fileUploadInputId = this.dataset.fileuploadinputid;
		const input = document.getElementById(fileUploadInputId);
		if(input){
			input.click();
		}
	});
	
	$(document).on("change", 'input[type="file"]#uploadAdminDocument', function(e){
		processDocument([...e.target.files], $(this));
	});
	
	
	$(document).on('dragover', '.fileDropZoneDocument', function(e){
		e.preventDefault();
		e.stopPropagation();
	});
	
	$(document).on('drop', '.fileDropZoneDocument', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		let dropZoneEl = e.target;
		if(dropZoneEl.tagName === 'SPAN'){
			dropZoneEl = dropZoneEl.parentElement;
		}
		const fileUploadInputId = dropZoneEl.dataset.fileuploadinputid;
		
		processDocument([...e.originalEvent.dataTransfer.files], $(`#${fileUploadInputId}`));
	});
}

const beautifyCode = (content) => {
	return html_beautify(content);
};


function transformElement(element) {
	if (element.children) {
		var children = element.children.map(transformElement);
		element = {...element, children: children};
	}

	if (element.type === "paragraph") {
		element = transformParagraph(element);
	}
	
	return element;
}

function transformParagraph(element) {
	if (element.alignment === "center") {
		return {...element, styleName: "center-aligned"};
	} else if (element.alignment === "both") {
		return {...element, styleName: "justify-aligned"};
	} else if (element.alignment === "right") {
		return {...element, styleName: "right-aligned"};
	} else if (element.alignment === "left") {
		return {...element, styleName: "left-aligned"};
	} else {
		return element;
	}
}

function transformHTML(html) {
	const doc = new DOMParser().parseFromString(html, "text/html");
	doc.querySelectorAll("[class]").forEach((el) => {
		const classes = el.getAttribute("class").split(" ");
		const style = classes.reduce((acc, className) => {
			
			// Add additional cases to handle other classes as needed
			switch (className) {
				case "center-aligned":
					return acc + "text-align: center;";
				case "justify-aligned":
					return acc + "text-align: justify;";
				case "left-aligned":
					return acc + "text-align: left;";
				case "right-aligned":
					return acc + "text-align: right;";
				default:
					return acc;
			}
			
		}, "");
		el.removeAttribute("class");
		el.setAttribute("style", style);
	});
	return doc.documentElement.innerHTML;
}

function processDocument(files, parent){
	
	var options = {
		ignoreEmptyParagraphs: false,
		transformDocument: transformElement,
		styleMap: [
			//"p[style-name='Section Title'] => h1:fresh",
			//"p[style-name='Subsection Title'] => h2:fresh",
			
			"p[style-name='center-aligned'] => p.center-aligned:fresh",
			"p[style-name='justify-aligned'] => p.justify-aligned:fresh",
			"p[style-name='left-aligned'] => p.left-aligned:fresh",
			"p[style-name='right-aligned'] => p.right-aligned:fresh",
		]
	};
	
	const file = files[0];
	if (file) {
		if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.name.endsWith('.docx')) {
			const reader = new FileReader();
			reader.onload = (e) => {
			mammoth.convertToHtml({ arrayBuffer: e.target.result }, options)
				.then((result) => {
					const htmlContent = result.value;
					const htmlContentWithStyle = transformHTML(htmlContent);
					const beautifiedContent = beautifyCode(htmlContentWithStyle);
					
					tinymce.get('tinymce').getBody().innerHTML = beautifiedContent;
					
				}).catch((err) => {
					console.error('Error converting DOCX to HTML:', err);
				});
			};
			reader.readAsArrayBuffer(file);
		} else {
			alert('Datoteka ni DOCX!');
		}
	}
}

$(function(){
	fileDropZoneDocument();
});
