
function sessionTimeout(){
	//Max session lifetime defined in PHP Classes/Session/construct
	const timeout = 15 /* minutes */ * 60 /* seconds */ * 1000 /* miliseconds */;
	setInterval(function(){
		fetch('/check-session')
		.then(response => {
			if (!response.ok) {
				// If the response is not OK, redirect to the login page
				window.location.href = '/login';
			}
		})
		.catch(error => {
			console.error('Error checking session:', error);
		});
	}, timeout);
}

$(function($){
	sessionTimeout();
})
