
function checkWhitespace(str) {
	return /\s/.test(str);
}

function validateSlovenianVAT(vatNumber) {
	// Remove spaces and convert to uppercase
	vatNumber = vatNumber.replace(/\s/g, '').toUpperCase();
	var number = vatNumber;
	
	// If the country code is present, check if it's valid for Slovenia
	if (vatNumber.substring(0, 2) === 'SI') {
		// Extract the number part
		number = vatNumber.substring(2);
	}
	
	// Check if the VAT number is in a valid format
	if (!/^[0-9]{8}$/.test(number)) {
		return false;
	}
	
	// Checks the check digits of a Slovenian VAT number.
	var total = 0;
	var multipliers = [8,7,6,5,4,3,2];
	
	// Extract the next digit and multiply by the counter.
	for (var i = 0; i < 7; i++){
		total += Number(number.charAt(i)) * multipliers[i];
	}
	
	// Establish check digits using modulus 11
	total = 11 - total % 11;
	total = total == 10 ? 0 : total;
	
	// Compare the number with the last character of the VAT number. If it is the
	// same, then it's a valid check digit.
	return (total != 11 && total == number.slice (7,8))
}

function validatePassword() {
	// Get password and repeat password values
	const password = document.getElementById("password").value;
	const passwordRepeat = document.getElementById("password2").value;
	
	// Check if passwords match
	if (password == '' || passwordRepeat == '') {
		return {'error': ''};
	}
	
	// Check if passwords match
	if (password !== passwordRepeat) {
		return {'error': 'Vneseni gesli se ne ujemata!'};
	}
	
	// Check if password contains at least one uppercase letter
	if (!/[A-Z]/.test(password)) {
		return {'error': 'Geslo mora vsebovati vsaj eno veliko črko.'};
	}
	
	// Check if password contains at least one lowercase letter
	if (!/[a-z]/.test(password)) {
		return {'error': 'Geslo mora vsebovati vsaj eno malo črko.'};
	}
	
	// Check if password contains at least one digit
	if (!/\d/.test(password)) {
		return {'error': 'Geslo mora vsebovati vsaj eno števko.'};
	}
	
	// Check if password contains at least one special character
	if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
		return {'error': 'Geslo mora vsebovati vsaj en poseben znak.'};
	}
	
	// Check if password is shorter than 8 characters
	if (password.length < 8) {
		return {'error': 'Geslo mora biti dolgo vsaj 8 znakov.'};
	}
	
	// If all checks pass, the password is valid
	return true;
}

function searchVAT(){
	$('#getvatnumberdata').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		const vatNum = $('#vatnumber').val();
		if(vatNum != ''){
			
			if(checkWhitespace(vatNum)){
				toasts.show({
					message:"Davčno številko vpišite brez presledkov.",
					color: 'danger',
					time: 10000
				});
			}else{
				if(!validateSlovenianVAT(vatNum)){
					toasts.show({
						message:"Davčna številka ni veljavna.",
						color: 'danger',
						time: 10000
					});
				}else{
					$.get('/search/vat/'+vatNum, function(data){
						if(!fnc.isJsonString(data)){
							console.log(data);
						}
						var jsonData = JSON.parse(data);
						if(data.length > 5 && !jsonData.error){
							
							$('#vatnumber').val(jsonData.xmlDavcnaStevilka);
							$('#registrationnumber').val(jsonData.xmlMaticnaStevilka);
							$('#name').val(jsonData.xmlNaziv);
							$('#address').val(jsonData.xmlNaslov);
							
							if(jsonData.xmlNaslov != ''){
								var naslov = jsonData.xmlNaslov.split(`,`);
								$('#address').val(naslov[0]);
								$('#postcode').val(naslov[1]);
							}
							
							if(jsonData.xmlZavezanecZaDDV == "true"){
								document.getElementById("zavezanecDDV_DA").checked = true;
								document.getElementById("zavezanecDDV").value = 1;
							}else{
								document.getElementById("zavezanecDDV_DA").checked = false;
								document.getElementById("zavezanecDDV").value = 0;
							}
							
							let trr = jsonData.xmlTransakcijskiRacuni.xmlTransakcijskiRacun.xmlTRR;
							let trrNew = trr;
							if (!isNaN(trr[0])) {
								trrNew = 'SI56 ' + trr;
							}
							$('#trr').val(trrNew);
							
						} else {
							toasts.show({
								message:jsonData.error,
								color: 'danger',
								time: 10000
							});
						}
					});
				}
			}
		}
	});
}

function validateRegistration(){
	$('.validateFormRegister').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		$('.is-invalid').removeClass('is-invalid');
		$('.invalid-feedback').remove();
		var valid = true;
		$( ':input[required]', $('form#registration')).each(function(element){
			if(this.value.trim() == '' && $(this).parent().is(':visible')){
				valid = false;
				$(this).addClass('is-invalid');
				$(this).attr('aria-invalid', true);
				var thisId = $(this).attr('id');
				$(this).attr('aria-errormessage', `err${thisId}`);
				$(this).after(`<div id="err${thisId}" class="invalid-feedback">Tega polja niste izpolnili!</div>`);
			}
		});
		
		const passwordValid = validatePassword();
		if( passwordValid !== true ){
			valid = false;
			
			if(passwordValid.error != ''){
				const passInput = '#password';
				$(passInput).addClass('is-invalid');
				$(passInput).attr('aria-invalid', true);
				var passInputId = $(passInput).attr('id');
				$(passInput).attr('aria-errormessage', `err${passInputId}`);
				$(passInput).after(`<div id="err${passInputId}" class="invalid-feedback">${passwordValid.error}</div>`);
				
				const passRepeatInput = '#password2';
				$(passRepeatInput).addClass('is-invalid');
				$(passRepeatInput).attr('aria-invalid', true);
				var passRepeatInputId = $(passRepeatInput).attr('id');
				$(passInput).attr('aria-errormessage', `err${passRepeatInputId}`);
				$(passRepeatInput).after(`<div id="err${passInputId}" class="invalid-feedback">${passwordValid.error}</div>`);
				
			}
		}
		
		if(!valid){
			toasts.show({
				message:'Pravilno izpolnite vsa zahtevana polja!',
				color: 'danger',
				time: 10000
			});
		} else {
			if(document.getElementById('registration')){
				document.getElementById('registration').submit();
			}else if(document.getElementById('passwordReset')){
				document.getElementById('passwordReset').submit();
			}
		}
	});
	$(':input[required]', $('form#registration')).on('change', function(){ // remove invalid red border when user changes
		if(this.value.trim() != ''){
			$(this).removeClass('is-invalid');
			$(this).removeAttr('aria-invalid');
			$(this).removeAttr('aria-errormessage');
			$(this).closest('.invalid-feedback').remove();
		}
	});
}

$(function(){
	
	searchVAT();
	validateRegistration();
	
});